<template>
  <ws-button color="primary" outlined :icon="noText" text @click="$emit('go-back')">
    <ws-icon regular>arrow-left</ws-icon>
    <template v-if="!noText">
      {{ $t("common.back") }}
    </template>
  </ws-button>
</template>

<script>
import WsButton from "@/components/base/WsButton";
import WsIcon from "@/components/base/WsIcon";

export default {
  name: "BackButton",
  components: {
    WsButton,
    WsIcon,
  },
  props: {
    noText: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
