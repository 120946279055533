<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    width="100%"
    height="100%"
    viewBox="0 0 117 121"
  >
    <defs>
      <rect id="a" width="117" height="121" rx="4" />
      <path
        id="b"
        d="M4 15h61.171l4.674-6.947H113a4 4 0 0 1 4 4V117a4 4 0 0 1-4 4H4a4 4 0 0 1-4-4V19a4 4 0 0 1 4-4z"
      />
    </defs>
    <g fill="none" fill-rule="evenodd">
      <g>
        <use :fill="mainColor" xlink:href="#a" />
        <rect
          width="116"
          stroke-width="2"
          height="120"
          x=".5"
          y=".5"
          :stroke="outlineColor"
          rx="4"
        />
      </g>
      <g>
        <use :fill="mainColor" xlink:href="#b" />
        <path
          :stroke="outlineColor"
          stroke-width="2"
          d="M70.111 8.553L65.438 15.5H4A3.5 3.5 0 0 0 .5 19v98a3.5 3.5 0 0 0 3.5 3.5h109a3.5 3.5 0 0 0 3.5-3.5V12.053a3.5 3.5 0 0 0-3.5-3.5H70.111z"
        />
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: "DynamicFolderIcon",
  props: ["outlineColor", "mainColor"],
};
</script>

<style scoped></style>
