<template>
  <base-folder :folder="folder" @open-folder="$emit('open-folder')">
    <template #default="{ openFolder, getImagePath, folderColor }">
      <div class="folder-list">
        <div class="folder-list-item folder-list-name" @click="openFolder">
          <span class="folder-list-icon">
            <img v-if="folder.logo" :src="getImagePath(folder.logo)" alt="" />
            <dynamic-folder-icon
              v-else
              :main-color="folderColor"
              outline-color="#fff"
            />
          </span>
          <p class="folder-list-name_title">{{ folder.title }}</p>
        </div>
        <p class="folder-list-item folder-list-date folder-list-date_para">
          {{ folder.date }} {{ $t("common.timeInDate") }} {{ folder.time }}
        </p>
        <item-checkbox
          v-if="availableDownload"
          :id="folder.id"
          :items="selectedFoldersIds"
          class="folder-list__control"
          :class="{
            'folder-list__control--checked': selectedFoldersIds.includes(folder.id),
          }"
          position="right"
          @input="$emit('select-folder', $event)"
        />
      </div>
    </template>
  </base-folder>
</template>

<script>
import DynamicFolderIcon from "@/components/common/elements/DynamicFolderIcon";
import BaseFolder from "./BaseFolder";
import ItemCheckbox from "@/components/common/elements/ItemCheckbox";

export default {
  components: { DynamicFolderIcon, BaseFolder, ItemCheckbox },
  model: {
    prop: "selectedFoldersIds",
    event: "select-folder",
  },
  props: {
    folder: {
      type: Object,
      default: () => ({}),
    },
    selectedFoldersIds: {
      type: Array,
      default: () => [],
    },
    availableDownload: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="less" scoped>
.folder-list {
  position: relative;
  display: flex;
  font-size: 15px;
  color: #363636;
  border-bottom: solid 1px var(--base-border-color);
  padding: 10px 35px 10px 10px;

  &__control {
    opacity: 1;
    transition: opacity 0.3s;

    @media screen and (min-width: 1024px) {
      opacity: 0;
    }

    &--checked {
      opacity: 1;
    }
  }

  &:hover &__control {
    opacity: 1;
  }

  img {
    max-width: 100%;
    max-height: 100%;
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  &-item {
    cursor: pointer;
    overflow: hidden;
  }

  &-name {
    display: flex;
    align-items: center;
  }

  &-desc {
    padding-right: 30px;
  }

  &-icon {
    display: inline-block;
    width: 49.6px;
    height: 49.6px;
    border-radius: 4px;
    overflow: hidden;
    margin-right: 10px;
    flex-shrink: 0;
  }

  &-date,
  &-size {
    flex: 1 1 10%;
    font-size: 13px;
    color: var(--mute-text-color);
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: initial;
  }
  &-name,
  &-desc {
    flex: 1 1 40%;
  }

  &-name_title,
  &-desc_para,
  &-date_para {
    margin: 0;
    padding: 0;
  }
}

.folder-list-name_title {
  overflow-wrap: break-word;
  overflow: hidden;
}
</style>
