<template>
  <div
    class="item__controls"
    :class="{
      'item__controls--right': position === 'right',
    }"
  >
    <input
      :id="`${idPrefix}${id}`"
      :checked="items.includes(id)"
      class="item__checkbox visually-hidden"
      type="checkbox"
      @change="selectItem(id)"
    />
    <label class="item__label" :for="`${idPrefix}${id}`" />
    <slot />
  </div>
</template>

<script>
export default {
  name: "ItemCheckbox",
  model: {
    prop: "items",
  },
  props: {
    id: {
      type: [Number, String],
      default: 0,
    },
    items: {
      type: Array,
      default: () => [],
    },
    position: {
      type: String,
      default: "default",
      validator: val => {
        return ["default", "right"].includes(val);
      },
    },
    idPrefix: {
      type: String,
      default: "",
    },
  },
  methods: {
    selectItem(id) {
      const newItems = [...this.items];
      const index = newItems.indexOf(id);
      if (index >= 0) {
        newItems.splice(index, 1);
      } else {
        newItems.push(id);
      }
      this.$emit("input", newItems);
    },
  },
};
</script>

<style scoped lang="less">
@import url("~@/styles/_mixin.less");
@import "~@/styles/components/icons/_variables.less";
@import "~@/styles/components/icons/solid.less";

.item {
  &__controls {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: 10;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;

    &--right {
      left: auto;
      top: 50%;
      transform: translate(12px, -50%);
    }
  }

  &__label {
    position: relative;
    padding-left: 30px;
    width: 16px;
    height: 16px;
    &::before {
      content: "";
      position: absolute;
      left: 0;
      width: 16px;
      height: 16px;
      background-color: #fff;
      border-radius: 3px;
      border: 1px solid rgba(#000, 0.2);
    }
    &::after {
      display: none;
      .fas;
      content: @fa-var-check;
      position: absolute;
      left: 3px;
      top: 4px;
      font-size: 10px;
      color: #fff;
    }
  }
}

.item__checkbox:checked + .item__label::after {
  display: block;
}

.item__checkbox:checked + .item__label::before {
  background-color: var(--base-color);
}
</style>
