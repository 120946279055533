<template>
  <div class="ws-flex-row"><slot></slot></div>
</template>

<script>
export default {};
</script>

<style lang="less">
.ws-flex-row {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}
</style>
