<template>
  <div class="toggle">
    <button
      :class="{ 'toggle-btn--active': isFolderView }"
      class="toggle-btn toggle-btn_folder"
      @click="$emit('toggle', true)"
    >
      &nbsp;
    </button>
    <button
      :class="{ 'toggle-btn--active': !isFolderView }"
      class="toggle-btn toggle-btn_list"
      @click="$emit('toggle', false)"
    >
      &nbsp;
    </button>
  </div>
</template>

<script>
export default {
  props: ["isFolderView"],
};
</script>

<style lang="less" scoped>
@import url("../../../../../styles/_vars");
@import url("../../../../../styles/_mixin");
.toggle {
  display: flex;
  justify-content: space-around;
  width: 88px;
  height: 24px;
  border-radius: 10px;
  background: #f4f4f4;
}

.toggle-btn {
  .button-reset();
  color: var(--base-color);
  opacity: 0.2;

  &--active {
    opacity: 1;
  }

  &:before {
    font-family: "simple-line-icons";
    display: inline-block;
    font-size: 15px;
    line-height: 24px;
  }

  &_folder {
    &:before {
      content: "\e06a";
    }
  }

  &_list {
    &:before {
      content: "\e601";
    }
  }
}
</style>
