<script>
import { makeUrl } from "@/utils";

export default {
  name: "withUrlParser",
  computed: {
    filePath() {
      return makeUrl(this.file.file);
    },
  },
  methods: {
    makeUrl(url) {
      return makeUrl(url);
    },
  },
};
</script>
