<template>
  <div class="file-list" @click="sendLogs">
    <div class="file-list-item file-list-name">
      <a v-if="file.logo" :href="filePath" target="_blank">
        <img :src="makeUrl(file.logo)" class="file-list-item file-list-img with-logo" />
      </a>
      <a v-else-if="/jpg|jpeg|png|gif/.test(file.filetype)" :href="filePath" target="_blank">
        <img :src="filePath" class="file-list-item file-list-img" />
      </a>

      <a v-else-if="file.filetype === 'external_link'" :href="file.file" target="_blank">
        <span class="file-list-link" />
      </a>

      <a v-else :href="filePath" target="_blank">
        <span class="file-list-icon" />
      </a>
      <p v-if="file.filetype === 'external_link'" class="file-list-name_title">
        <a :href="file.file" target="_blank">{{ file.title }}</a>
      </p>
      <p v-else class="file-list-name_title">
        <a :href="filePath" target="_blank">{{ file.title }}</a>
      </p>
    </div>
    <p class="file-list-item file-list-date file-list-date_para">
      {{ file.date }} {{ $t("common.timeInDate") }} {{ file.time }}
    </p>
    <item-checkbox
      v-if="availableDownload"
      :id="file.id"
      :items="selectedFileIds"
      class="file-list__control"
      :class="{
        'file-list__control--checked': selectedFileIds.includes(file.id),
      }"
      position="right"
      @input="$emit('select-file', $event)"
    />
  </div>
</template>

<script>
import ItemCheckbox from "@/components/common/elements/ItemCheckbox";
import withUrlParser from "../mixin/withUrlParser.mixin";

export default {
  name: "FileList",
  components: { ItemCheckbox },
  mixins: [withUrlParser],
  model: {
    prop: "selectedFileIds",
    event: "select-file",
  },
  props: {
    file: {
      type: Object,
      default: () => ({}),
    },
    selectedFileIds: {
      type: Array,
      default: () => [],
    },
    availableDownload: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    sendLogs(event) {
      const isLink = event.target.tagName === "A" || event.target.parentNode.tagName === "A";
      if (isLink) {
        this.$emit("send-logs", this.file);
      }
    },
  },
};
</script>

<style lang="less" scoped>
@import url("~@/styles/_mixin");
@import url("~@/styles/_vars");

.file-list {
  position: relative;
  display: flex;
  font-size: 15px;
  color: #363636;
  border-bottom: solid 1px var(--base-border-color);
  padding: 10px 35px 10px 10px;

  &__control {
    opacity: 1;
    transition: opacity 0.3s;

    @media screen and (min-width: 1024px) {
      opacity: 0;
    }

    &--checked {
      opacity: 1;
    }
  }

  &:hover &__control {
    opacity: 1;
  }

  &-item {
    overflow: hidden;
  }

  &-name {
    display: flex;
    align-items: center;
  }

  &-desc {
    padding-right: 30px;
    display: flex;
    align-items: center;
  }

  &-img {
    display: block;
    width: 48px;
    height: 49.6px;
    margin-right: 10px;
    border-radius: 4px;
    border: solid 1px var(--base-color);
    &.with-logo {
      object-fit: contain;
    }
  }

  &-icon,
  &-link {
    display: inline-block;
    width: 48px;
    height: 49.6px;
    border-radius: 4px;
    overflow: hidden;
    margin-right: 10px;
    border: solid 1px var(--base-bg-color);
    position: relative;
    color: var(--base-bg-color);

    &:before {
      font-family: "simple-line-icons";
      display: inline-block;
      height: 100%;
      width: 100%;
      font-size: 32px;
      text-align: center;
      line-height: 49.6px;
      opacity: 0.1;
    }
  }

  &-link {
    &:before {
      content: "\e08d";
    }
  }

  &-icon {
    &:before {
      content: "\e085";
    }
  }

  &-date,
  &-size {
    flex: 1 1 10%;
    font-size: 13px;
    color: #898989;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &-name,
  &-desc {
    flex: 1 1 40%;
  }

  &-name_title,
  &-desc_para,
  &-date_para {
    margin: 0;
    padding: 0;
    overflow: visible;
  }

  &-name_title,
  &-desc_para {
    overflow: hidden;
    overflow-wrap: break-word;
  }
  &-name_title {
    & > a {
      &:visited,
      &:link,
      &:hover,
      &:active {
        color: inherit;
        text-decoration: none;
      }
    }
  }
}

.file-list-img {
  .object-fit();
}
</style>
