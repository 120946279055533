<template>
  <base-folder :folder="folder" @open-folder="$emit('open-folder')">
    <template #default="{ openFolder, getImagePath, folderColor }">
      <div class="folder__container">
        <item-checkbox
          v-if="availableDownload"
          :id="folder.id"
          :items="selectedFoldersIds"
          class="folder__control"
          :class="{
            'folder__control--checked': selectedFoldersIds.includes(folder.id),
          }"
          @input="$emit('select-folder', $event)"
        />
        <div
          class="folder"
          :class="{ 'with-image': folder.logo }"
          @click="openFolder"
        >
          <span class="folder-title">{{ folder.title }}</span>
          <img v-if="folder.logo" :src="getImagePath(folder.logo)" alt="" />
          <dynamic-folder-icon
            v-else
            :main-color="folderColor"
            outline-color="#fff"
          />
        </div>
      </div>
    </template>
  </base-folder>
</template>

<script>
import DynamicFolderIcon from "@/components/common/elements/DynamicFolderIcon";
import ItemCheckbox from "@/components/common/elements/ItemCheckbox";
import BaseFolder from "./BaseFolder";

export default {
  components: { DynamicFolderIcon, BaseFolder, ItemCheckbox },
  model: {
    prop: "selectedFoldersIds",
    event: "select-folder",
  },
  props: {
    folder: {
      type: Object,
      default: () => ({}),
    },
    selectedFoldersIds: {
      type: Array,
      default: () => [],
    },
    availableDownload: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="less" scoped>
.folder {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  overflow: hidden;
  font-size: 12px;
  text-align: center;
  cursor: pointer;
  border-radius: 4px;

  &__control {
    opacity: 1;
    transition: opacity 0.3s;

    @media screen and (min-width: 1024px) {
      opacity: 0;
    }

    &--checked {
      opacity: 1;
    }
  }

  &__container:hover &__control {
    opacity: 1;
  }

  .folder-title {
    color: #fff;
  }
  img {
    max-width: 100%;
    max-height: 100%;
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
  svg {
    transform: scale(1.05, 1.05);
  }
  .folder-title {
    position: absolute;
    bottom: 5px;
    z-index: 5;
    width: 80%;
    margin: 0;
    padding: 5px 5px 2px;
    line-height: 1em;
    text-overflow: ellipsis;
    overflow: hidden;
    word-break: break-word;
    border-radius: 3px;
  }

  &.with-image {
    .folder-title {
      background-color: var(--base-color);
      color: #fff;
      font-weight: 700;
    }
  }
}
</style>
