<template>
  <div class="file__container" @click="sendLogs">
    <item-checkbox
      v-if="availableDownload"
      :id="file.id"
      id-prefix="file"
      :items="selectedFileIds"
      class="file__control"
      :class="{
        'file__control--checked': selectedFileIds.includes(file.id),
      }"
      @input="$emit('select-file', $event)"
    />

    <a
      v-if="file.logo"
      :href="filePath"
      target="_blank"
      class="file with-cover"
      :style="{ backgroundImage: `url(${makeUrl(file.logo)})` }"
    >
      <span v-if="file.filetype !== 'external_link'" class="file-ext">
        {{ file.filetype }}
      </span>
      <p class="file-title">{{ file.title }}</p>
      <a :href="filePath" target="_blank">
        <span v-if="file.filetype !== 'external_link'" class="file-ddl" />
      </a>
    </a>

    <div v-else-if="/jpg|jpeg|png|gif/.test(file.filetype)" class="file-img_container">
      <a :href="filePath" target="_blank">
        <img class="file-img" :src="filePath" alt="" />
      </a>
    </div>

    <a
      v-else-if="file.filetype === 'external_link'"
      class="file-link"
      :href="file.file"
      target="_blank"
    >
      <p class="file-link-title">{{ file.title }}</p>
    </a>

    <a v-else :href="filePath" target="_blank" class="file">
      <span class="file-ext">{{ file.filetype }}</span>
      <p class="file-title">{{ file.title }}</p>
      <a :href="filePath" target="_blank">
        <span class="file-ddl" />
      </a>
    </a>
  </div>
</template>

<script>
import ItemCheckbox from "@/components/common/elements/ItemCheckbox";
import withUrlParser from "../mixin/withUrlParser.mixin";

export default {
  name: "FileTiles",
  components: { ItemCheckbox },
  mixins: [withUrlParser],
  model: {
    prop: "selectedFileIds",
    event: "select-file",
  },
  props: {
    file: {
      type: Object,
      default: () => ({}),
    },
    selectedFileIds: {
      type: Array,
      default: () => [],
    },
    availableDownload: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    sendLogs(event) {
      const isLink = event.target.tagName === "A" || event.target.parentNode.tagName === "A";
      if (isLink) {
        this.$emit("send-logs", this.file);
      }
    },
  },
};
</script>

<style lang="less" scoped>
.file {
  border-radius: 4px;
  border: solid 1px var(--base-bg-color);
  text-align: left;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  overflow: hidden;
  font-size: 10px;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  &__control {
    opacity: 1;
    transition: opacity 0.3s;

    @media screen and (min-width: 1024px) {
      opacity: 0;
    }

    &--checked {
      opacity: 1;
    }
  }

  &__container:hover &__control {
    opacity: 1;
  }

  &.with-cover {
    background: no-repeat center center;
    background-size: contain;
    .file-title {
      padding: 6px;
      background-color: var(--base-color);
      color: #fff;
      font-weight: 700;
      border-bottom-right-radius: 6px;
      border-top-right-radius: 6px;
    }
    &:before {
      content: "";
    }
  }

  &:before {
    font-family: "simple-line-icons";
    content: "\e085";
    display: inline-block;
    font-size: 48px;
    color: var(--base-color);
    opacity: 0.1;
    pointer-events: none;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  // &:not(:last-child) {
  //   margin-right: 10px;
  // }
  &-img_container {
    border-radius: 4px;
    border: solid 1px var(--base-bg-color);
    overflow: hidden;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
  }

  &-link {
    display: block;
    border-radius: 4px;
    border: solid 1px var(--base-bg-color);
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    &:hover,
    &:visited,
    &:active,
    &:link {
      color: inherit;
      text-decoration: none;
    }
    &:before {
      font-family: "simple-line-icons";
      content: "\e08d";
      display: inline-block;
      font-size: 48px;
      color: var(--base-color);
      opacity: 0.1;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    &-title {
      margin: 0;
      padding: 0 0 0 6px;
      font-size: 12px;
      color: #383838;
      max-height: 44px;
      line-height: 0.98em;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
    }
  }
  &-img {
    height: 100%;
    width: 100%;
    max-width: 100%;
    max-height: 100%;
    object-fit: cover;
  }
  &-title {
    padding: 0 0 0 6px;
    margin: 0;
    color: var(--base-text-color);
    line-height: 0.98em;
    max-height: 44px;
    text-overflow: ellipsis;
    overflow: hidden;
    word-break: break-word;
  }
  &-ext {
    display: inline-block;
    min-width: 30px;
    height: 15px;
    line-height: 15px;
    text-align: center;
    font-size: 12px;
    font-weight: 600;
    color: #fff;
    border-radius: 7px;
    background-color: var(--base-bg-color);
    position: absolute;
    top: 6px;
    right: 6px;
  }
  &-ddl {
    cursor: pointer;
    display: inline-block;
    width: 19px;
    height: 19px;
    border: 0.6px solid var(--base-color);
    color: var(--base-color);
    border-radius: 50%;
    line-height: 19px;
    text-align: center;
    position: absolute;
    right: 6px;
    bottom: 6px;
    &:before {
      font-family: "simple-line-icons";
      content: "\e083";
      display: inline-block;
    }
  }
}
</style>
