<template>
  <flex-column-stack
    class="materials"
    :class="{ removePadding: !isFolderVisible, addPadding: isFolderVisible }"
  >
    <flex-row-wrap class="toggle-container">
      <back-button v-if="hasBack" class="back-btn" :no-text="$mqMobile" @go-back="goBack" />
      <download-header
        v-if="isAvailableDownload"
        :is-short-button="$mqMobile && hasBack"
        :is-disable-selected-button="selectedFileIds.length < 1 && selectedFolderIds.length < 1"
        :disable-download-all="!folders.length && !files.length"
        :all-items-url="allFilesUrl"
        :selected-items-url="selectedItemsUrl"
        @clear-item="clearSelectedItems"
      />
      <toggle-folder-view
        class="toggle-button"
        :class="{
          addMarginToggle: !isFolderVisible,
          removeMarginToggle: isFolderVisible,
        }"
        :is-folder-view="isFolderVisible"
        @toggle="toggleFolderVue"
      />
    </flex-row-wrap>

    <section v-if="isFolderVisible" class="photowall content" :style="{ marginTop: '10px' }">
      <div class="photowall_content_guest">
        <folder-tiles
          v-for="folder in folders"
          :key="`folder_${folder.id}`"
          v-model="selectedFolderIds"
          :available-download="isAvailableDownload"
          :folder="folder"
          class="photo"
          @open-folder="clearSelectedItems"
        />
        <file-tiles
          v-for="file in files"
          :key="`file_${file.id}`"
          v-model="selectedFileIds"
          :available-download="isAvailableDownload"
          :file="file"
          class="photo"
          @send-logs="sendLogs"
        />
      </div>
    </section>

    <flex-column-stack v-else class="list">
      <list-heading />
      <folder-list
        v-for="folder in folders"
        :key="folder.id"
        v-model="selectedFolderIds"
        :available-download="isAvailableDownload"
        :folder="folder"
        @open-folder="clearSelectedItems"
      />
      <file-list
        v-for="file in files"
        :key="file.id"
        v-model="selectedFileIds"
        :available-download="isAvailableDownload"
        :file="file"
        @send-logs="sendLogs"
      />
    </flex-column-stack>
    <infinite-loading :identifier="folderId" @infinite="loadmore">
      <div slot="spinner"><loader /></div>
      <div slot="no-more" />
      <div slot="no-results">
        {{ folders.length > 0 ? "" : $t("Materials.emptyFolderFile") }}
      </div>
    </infinite-loading>
  </flex-column-stack>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
import InfiniteLoading from "vue-infinite-loading";
import FlexColumnStack from "@/components/event/layouts/room/FlexColumnStack";
import ToggleFolderView from "@/components/event/room/Materials/components/ToggleFolderView";
import FolderTiles from "@/components/event/room/Materials/components/FolderTiles";
import FileTiles from "@/components/event/room/Materials/components/FileTiles";
import FolderList from "@/components/event/room/Materials/components/FolderList";
import FileList from "@/components/event/room/Materials/components/FileList";
import ListHeading from "@/components/event/room/Materials/components/ListHeading";
import Loader from "@/components/common/elements/Loader";
import { GET_MATERIALS } from "@/store/modules/materials/actions-types";
import { materials, room, auth } from "@/store/modules/store.namespaces";
import { CLEAR_MATERIALS, TOGGLE_FOLDER_VISIBLE } from "@/store/modules/materials/mutation-types";
import FlexRowWrap from "@/components/event/layouts/room/FlexRowWrap";
import BackButton from "@/components/common/elements/BackButton";
import config from "@/settings/config";
import { AVAILABLE_ROOM_MODULES } from "@/store/modules/common/getter-types";
import DownloadHeader from "@/components/common/elements/DownloadHeader";

export default {
  name: "Materials",
  components: {
    FlexColumnStack,
    ToggleFolderView,
    FolderTiles,
    FolderList,
    FileTiles,
    FileList,
    ListHeading,
    Loader,
    InfiniteLoading,
    BackButton,
    FlexRowWrap,
    DownloadHeader,
  },
  data() {
    return {
      selectedFolderIds: [],
      selectedFileIds: [],
    };
  },
  computed: {
    ...mapState(materials, ["lastLoadedFileKey", "folders", "files", "isFolderVisible"]),
    ...mapState(room, ["roomNumber"]),
    ...mapState(auth, ["user"]),
    ...mapGetters(room, {
      availableRoomModules: AVAILABLE_ROOM_MODULES,
    }),
    baseDownloadUrl() {
      return `${config.serverUrl}/web/api/downloadLkFiles/${this.roomNumber}`;
    },
    allFilesUrl() {
      return `${this.baseDownloadUrl}/all/all`;
    },
    selectedItemsUrl() {
      return `${this.baseDownloadUrl}/${this.selectedFolderIds.join(",") ||
        "none"}/${this.selectedFileIds.join(",") || "none"}`;
    },
    hasBack() {
      return this.$route.meta?.hasBack;
    },
    folderId() {
      return this.$route.query.materialId;
    },
    isAvailableDownload() {
      return this.availableRoomModules.downloadMaterialsModule;
    },
  },
  watch: {
    folderId() {
      this.clearMaterials();
    },
  },
  beforeDestroy() {
    this.clearMaterials();
  },
  methods: {
    ...mapActions(materials, {
      getMaterials: GET_MATERIALS,
    }),
    ...mapMutations(materials, {
      clearMaterials: CLEAR_MATERIALS,
      toggleFolderView: TOGGLE_FOLDER_VISIBLE,
    }),
    clearSelectedItems() {
      this.selectedFolderIds = [];
      this.selectedFileIds = [];
    },
    toggleFolderVue(visibility) {
      if (this.isFolderVisible === visibility) {
        return;
      }
      this.toggleFolderView();
    },
    loadmore($state) {
      this.getMaterials({
        folderId: this.folderId || "",
        from: this.lastLoadedFileKey.listLength,
      }).then(res => {
        if (res && res === "complete") {
          $state.complete();
        } else {
          $state.loaded();
        }
      });
    },
    goBack() {
      this.$router.go(-1);
    },
    sendLogs(file) {
      this.$loggers.$materials.sendLog(
        this.$loggers.$materials.getPayload({
          file,
          room: this.roomNumber,
          user: this.user,
        }),
      );
    },
  },
};
</script>

<style lang="less" scoped>
@import url("~@/styles/_mixin");

.loader {
  justify-content: center;
}
.centered {
  justify-content: center;
}
.materials {
  flex-shrink: 0;
}

.toggle-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 10px;
}
.toggle-button {
  margin-left: auto !important;
}

.list {
  width: 100%;
}
.addPadding {
  padding: 10px;
}
.removePadding {
  padding: 10px 0 0;
}
.addMarginToggle {
  margin-right: 10px;
}
.removeMarginToggle {
  margin: 0;
}

.photowall {
  transform: translate3d(0, 0, 0);
  overflow-y: hidden;

  .photowall_content_guest {
    position: relative;
    width: 100%;
    font-size: 0;
    height: 100%;
    .photo {
      width: ~"calc(50% - 8px)";
      position: relative;
      display: inline-block;
      vertical-align: top;
      margin: 4px;

      @media screen and (min-width: 1350px) {
        width: ~"calc(16.66% - 8px)";
      }
      @media screen and (max-width: 1350px) {
        width: ~"calc(20% - 8px)";
      }
      @media screen and (max-width: 1150px) {
        width: ~"calc(25% - 8px)";
      }
      @media screen and (max-width: 950px) {
        width: ~"calc(33.3% - 8px)";
      }
      @media screen and (max-width: 760px) {
        width: ~"calc(25% - 8px)";
      }
      @media screen and (max-width: 550px) {
        width: ~"calc(33.33% - 8px)";
      }
      @media screen and (max-width: 400px) {
        width: ~"calc(50% - 8px)";
      }

      &:before {
        content: " ";
        display: block;
        padding-top: 100%;
      }
      .img {
        border: 1px solid #ececec;
        border-radius: 4px;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center center;
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
      }
    }
    h1 {
      font-size: 23pt/2;
    }
  }
  .photowall_content {
    height: 100%;
    overflow: hidden;
    .blocks {
      display: inline-block;
      vertical-align: top;
      width: 33%;
      &.block2 {
        margin: 0 0.3%;
      }
    }
    position: relative;
    font-size: 0;
    .photo {
      width: 100%;
      position: relative;
      margin: 5px 0;
      .img {
        width: 100%;
        border: 2px solid #a8a49d;
      }
    }
    h1 {
      font-size: 23pt/2;
    }
  }
}
.back-btn {
  margin-right: 10px;
}
</style>
