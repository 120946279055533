<template>
  <div class="ws-flex-column"><slot></slot></div>
</template>

<script>
export default {};
</script>

<style lang="less">
.ws-flex-column {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  flex-grow: 1;
  width: 100%;
}
</style>
