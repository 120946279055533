<template>
  <div class="heading">
    <p class="heading-item heading-name">{{ $t("Materials.name") }}</p>
    <!-- <p class="heading-item heading-desc">описание</p> -->
    <p class="heading-item heading-date">{{ $t("Materials.dateUpdate") }}</p>
  </div>
</template>

<script>
export default {};
</script>

<style lang="less" scoped>
.heading {
  display: flex;
  padding: 10px 35px 10px 65px;
  font-size: 13px;
  color: var(--mute-text-color);
  border-bottom: solid 1px var(--base-border-color);

  &-item {
    padding: 0;
    margin: 0;
  }

  &-name,
  &-desc {
    flex: 1 1 40%;
  }

  &-date,
  &-size {
    flex: 1 1 10%;
    text-align: center;
  }
}
</style>
