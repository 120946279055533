<template>
  <div class="download-header">
    <a
      :href="allItemsUrl"
      target="_blank"
      class="download-header__download-btn"
      :class="{
        'download-header__download-btn--disabled': disableDownloadAll,
      }"
      @click="$emit('clear-item')"
    >
      <ws-icon class="download-header__download-btn-icon">cloud-download-alt</ws-icon>
      {{ isShortButton ? "" : $t(`common.downloadAll${$mqToLaptop ? "Short" : ""}`) }}
    </a>
    <a
      class="download-header__download-btn"
      :class="{
        'download-header__download-btn--disabled': isDisableSelectedButton,
      }"
      :href="selectedItemsUrl"
      target="_blank"
      @click="clearItems"
    >
      <span class="download-header__download-btn-icon">
        <ws-icon>
          cloud-download-alt
        </ws-icon>
        <ws-icon class="download-header__download-btn-sub-icon">check-square</ws-icon>
      </span>
      {{ isShortButton ? "" : $t(`common.downloadSelected${$mqToLaptop ? "Short" : ""}`) }}
    </a>
  </div>
</template>

<script>
import WsIcon from "@/components/base/WsIcon";

export default {
  name: "DownloadHeader",
  components: {
    WsIcon,
  },
  props: {
    isShortButton: {
      type: Boolean,
      default: false,
    },
    isDisableSelectedButton: {
      type: Boolean,
      default: false,
    },
    allItemsUrl: {
      type: String,
      default: "",
    },
    selectedItemsUrl: {
      type: String,
      default: "",
    },
    disableDownloadAll: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    clearItems() {
      setTimeout(() => {
        this.$emit("clear-item");
      }, 0);
    },
  },
};
</script>

<style lang="scss" scoped>
.download-header {
  display: flex;
  padding: 10px 16px;

  &__download-btn {
    display: flex;
    align-items: center;
    height: 24px;
    padding: 0 10px;
    font-size: 14px;
    color: var(--base-color);
    background-color: #f4f4f4;
    border-radius: 15px;
    transition: all 0.1s linear;

    &:not(:first-of-type) {
      margin-left: 10px;
    }

    &:hover {
      background-color: darken(#f4f4f4, 10);
    }

    &--disabled {
      opacity: 0.6;
      pointer-events: none;
    }
  }

  &__download-btn-icon {
    position: relative;
    margin-right: 5px;

    @media screen and (min-width: 1024px) {
      display: none;
    }
  }

  &__download-btn-sub-icon {
    position: absolute;
    top: -3px;
    right: -2px;
    font-size: 9px;
    background-color: #f4f4f4;
    border: 1px solid #f4f4f4;
    border-radius: 1px;
  }
}
</style>
