<script>
import { mapState } from "vuex";
import { room } from "@/store/modules/store.namespaces";
import { makeUrl } from "@/utils";

export default {
  name: "BaseFolder",
  props: ["folder"],
  computed: {
    ...mapState(room, ["styleColors"]),
    folderColor() {
      return this.styleColors.baseColor;
    },
  },
  methods: {
    openFolder() {
      this.$router.push({
        name: "material",
        query: { materialId: this.folder.id },
      });
      this.$emit("open-folder");
    },
    imagePath(url) {
      return makeUrl(url);
    },
  },
  render() {
    return this.$scopedSlots.default({
      folderColor: this.folderColor,
      getImagePath: this.imagePath,
      openFolder: this.openFolder,
    });
  },
};
</script>

<style scoped></style>
